import React from "react";
import { Link } from "gatsby";

const FooterMenuItem = ({ text, link, children }) => (
    <article className="fl w-100 w-50-ns dib-ns w-auto-ns mr4-m mr5-l mb4">
      <Link to={link} className={"ma0 f6 link gray ttu tracked"}>{text}</Link>
      {children!=null ? <ul className={"list pl0 ma0"}>{children}</ul> :''}
    </article>
);

export default FooterMenuItem;
