import React from "react";
import Navigation from "../navigation/Navigation";
import MenuItem from "../navigation/MenuItem";
import SubMenuItem from "../navigation/SubMenuItem";

const Header = ({ hideLogo }) => {
  return (
    <header className="header">
      <Navigation hideLogo={hideLogo}>
        <MenuItem text={"Home"} link={"/"}/>
        <MenuItem text={"Products"} link={"/products/app"}>
          <SubMenuItem text={"Video First App"} link={"/products/app/"}/>
          <SubMenuItem text={"Slack Integration"} link={"/products/slack/"}/>
          {/*<SubMenuItem text={"Video First Capture"} link={"/products/capture/"}/>*/}
        </MenuItem>
        <MenuItem text={"Pricing"} link={"/pricing/"}/>
        {/* <MenuItem text={"Developers"} link={"/developers/docs"}>
          <SubMenuItem text={"Documentation"} link={"/developers/docs"}/>
          <SubMenuItem text={"Tutorials"} link={"/"}/>
          <SubMenuItem text={"Support"} link={"/"}/>
        </MenuItem> */}
        {/* <MenuItem text={"Contact"} link={"/contact"}/> */}
        <MenuItem text={"Blog"} link={"/blog/"}/>
        <MenuItem text={"About"} link={"/about/"}>
          <SubMenuItem text={"Terms and Conditions"} link={"/about/terms-and-conditions/"}/>
          <SubMenuItem text={"Privacy Policy"} link={"/about/privacy-policy/"}/>
        </MenuItem>
      </Navigation>
    </header>
  );
};

export default Header;
