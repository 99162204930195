import React from "react";
import InnerContainer from "./InnerContainer";

export const Container = ({ children, verticalPadding = true, extraClasses = "", maxWidth = false }, maxWidthPx = 1200) => (
  <div className={`${extraClasses} ph3 ph4-m ph5-l ${verticalPadding ? "pv3 pv6-ns" : ""}`}>
    <InnerContainer maxWidth={maxWidth}>
      {children}
    </InnerContainer>
  </div>
);

export default Container;
