import React from "react";
import Icon from "../icon";

import './buttons.scss';

export const PrimaryButton = ({ text, icon = null, link, action, onClick, extraClasses = "", targetBlank = false }) => (
  <a className={`${extraClasses} button-primary`}
     href={link}
     target={targetBlank ? '_blank' : '_self'}>
    {icon != null ? <Icon icon={icon} width={"2rem"} fill={"#fff"}/> : ""}
    <span className="ph2 tc w-100">{text}</span>
  </a>
);

export default PrimaryButton;
