import React from "react";
import { Link } from "gatsby";

export const NavbarButton = ({ text, link }) => (
  <a href={link}
     className={"button"}>
    <span>
      {text}
    </span>
  </a>
);

export default NavbarButton;
