import React from "react";
import { Link } from "gatsby";

const FooterSubMenuItem = ({ text, link }) => (
  <li className={"mv2"}>
    <Link to={link} className={"f6 link gray nowrap"}>{text}</Link>
  </li>
);

export default FooterSubMenuItem;
