import React from "react";
import Icon from "../icon";
import FooterMenuItem from "./FooterMenuItem";
import FooterSubMenuItem from "./FooterSubMenuItem";
import IconTwitter from "../icon/icons/twitter.icon";
import IconLinkedin from "../icon/icons/linkedin.icon";
import IconVF from "../icon/icons/vf-icon-rgb.icon";
import Container from "../container";

const Footer = () => (
  <footer className="footer dn db-m db-l">
    <Container verticalPadding={false} extraClasses={"pv4"} maxWidth={1200}>
      <div className="mb4-l cf">
        <FooterMenuItem text={"Home"} link={"/"}/>
        <FooterMenuItem text={"Products"} link={"/products/app/"}>
          <FooterSubMenuItem text={"Video First App"} link={"/products/app/"}/>
          {/* <FooterSubMenuItem text={"Video First Capture"} link={"/products/capture/"}/> */}
        </FooterMenuItem>
        {/* <FooterMenuItem text={"Developers"} link={"/developers/docs"}>
          <FooterSubMenuItem text={"Documentation"} link={"/developers/docs"}/>
          <FooterSubMenuItem text={"Tutorials"}/>
          <FooterSubMenuItem text={"Support"}/>
        </FooterMenuItem> */}
        <FooterMenuItem text={"Blog"} link={"/blog/"}/>
        <FooterMenuItem text={"About"} link={"/about/"}>
          <FooterSubMenuItem text={"Terms and Conditions"} link={"/about/terms-and-conditions/"}/>
          <FooterSubMenuItem text={"Privacy Policy"} link={"/about/privacy-policy/"}/>
        </FooterMenuItem>
        {/* <FooterMenuItem text={"Contact"}/> */}
      </div>
      <div className="dt dt--fixed w-100">
        <div className="dn dtc-ns v-mid">
          <p className="f7 black-70 dib pr3 mb3">
            <Icon icon={IconVF} width={"1.5em"} height={"1.5em"}/>
            <span className={"v-super pl2"}>
              &copy; Video First Software Ltd (NI649259)
            </span>
          </p>
        </div>
        <div className="db dtc-ns black-70 tc tr-ns v-mid">
          <a href="https://twitter.com/video_first_io" target="_blank"
             className="social-twitter dim dib mr2 black-70">
            <Icon icon={IconTwitter} width={"2em"} height={"2em"}/>
          </a>
          <a href="https://www.linkedin.com/company/video-first-software/" target="_blank"
             className="social-linkedin dim dib black-70">
            <Icon icon={IconLinkedin} width={"2em"} height={"2em"}/>
          </a>
        </div>
      </div>
      <div className="db dn-ns">
        <p className="f7 black-70 mt2 tc">
          &copy; Video First Software Ltd
        </p>
      </div>
    </Container>
  </footer>
);

export default Footer;
