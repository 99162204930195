import React, { Fragment } from "react";
import Navbar from './Navbar';
import Sidebar from './Sidebar';

import './navbar.scss';

const Navigation = ({children, hideLogo}) => (
  <Fragment>
    <Navbar hideLogo={hideLogo}>
      {children}
    </Navbar>
    <Sidebar>
      {children}
    </Sidebar>
  </Fragment>
);

export default Navigation;
