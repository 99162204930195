import React from "react";
import Icon from "../icon";
import Videofirst from "../icon/icons/vf-wordmark-rgb.icon";
import NavbarButton from "./NavbarButton";
import { Link } from "gatsby";

const Navbar = ({ children, hideLogo }) => (
  <>
    <div className="navigation df-tablet dn">
      <Link to="/" className={`navigation-element${hideLogo ? " hide" : ""}`}>
        <Icon icon={Videofirst} width={150}/>
      </Link>

      <nav className="navigation-element navbar">
        {children}
      </nav>

      <div className="navigation-element buttons">
        <NavbarButton link="https://app.videofirst.io/register" text="Sign Up"/>
        <NavbarButton link="https://app.videofirst.io/login" text="Login"/>
      </div>
    </div>

    <div className="w-100 bg-white ph4 flex justify-between">
      <Link to={"/"} className={"vft-icon"}>
      </Link>
    </div>

  </>
);

export default Navbar;
