import React from "react";
import { Link } from "gatsby";

const MenuItem = ({ text, link = "#", children, desktop = true }) => (
  <li className={"menu-item"}>
    <Link to={link} activeClassName={"sb"}>{text}</Link>
    {children ? <ul>{children}</ul> : ""}
  </li>
);

export default MenuItem;
