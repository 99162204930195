import React from "react";
import { Link } from 'gatsby';

const MenuItem = ({ text, link = "#" }) => (
  <li className={"sub-menu-item"}>
    <Link to={link} activeClassName={"sb"}>{text}</Link>
  </li>
);

export default MenuItem;
