import React from "react";

const Icon = (props, { extraClasses = "" }) => (
  <svg
    viewBox={props.icon.viewBox}
    width={props.width}
    height={props.height}
    fill={props.fill}
    className={extraClasses}
  >
    <use xlinkHref={`#${props.icon.id}`}/>
  </svg>
);

export default Icon;
