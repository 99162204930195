import React from "react";
import { stack as Menu } from "react-burger-menu";
import Icon from "../icon";
import MenuBars from "../icon/icons/bars.icon";
import Twitter from "../icon/icons/twitter.icon";
import Linkedin from "../icon/icons/linkedin.icon";
import VFIcon from "../icon/icons/vf-icon-rgb.icon";

const Sidebar = ({ children }) => (
  <Menu
    width={310}
    menuClassName="dn-tablet"
    overlayClassName="dn-tablet"
    burgerButtonClassName="dn-tablet"
    customBurgerIcon={<Icon icon={MenuBars} />}
    customCrossIcon={false}
    right
  >
    {children}
    <li className="sidebar-bottom">
      <div className="flex">
        <div className="dib v-mid w-50 pv3 f6 tracked ttu">
          <p className="mt1">Follow Us</p>
        </div>
        <div className="dib v-mid w-50 pa2 tr">
          <a
            className="social-twitter dim mr2"
            href="https://twitter.com/video_first_io">
            <Icon icon={Twitter} width={"2em"} height={"2em"} />
          </a>
          <a
            className="social-linkedin dim"
            href="https://www.linkedin.com/company/video-first-software/">
            <Icon icon={Linkedin} width={"2em"} height={"2em"}/>
          </a>
        </div>
      </div>
      <div className="cf">
        <div className="fl w-10">
          <Icon icon={VFIcon} width={"1.5em"} height={"1.5em"}/>
        </div>
        <div className="fl w-90 tr">
          <span className={"pv2 pl4 f7"}>©2018 Video First Software Ltd</span>
        </div>
      </div>
    </li>
  </Menu>
);

export default Sidebar;
